var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"training-report"},[_c('app-block-flex',[_c('app-query-bar',{attrs:{"queryConfig":_vm.queryConfig},on:{"onSearch":_vm.fetchReports},model:{value:(_vm.queryData),callback:function ($$v) {_vm.queryData=$$v},expression:"queryData"}},[_c('template',{slot:"numberRange"},[_c('app-spacing',{staticClass:"age-range",attrs:{"spacing":"sm"}},[_c('div',[_c('InputNumber',{attrs:{"max":150,"min":0},model:{value:(_vm.queryData.startNumber),callback:function ($$v) {_vm.$set(_vm.queryData, "startNumber", $$v)},expression:"queryData.startNumber"}})],1),_c('div',[_vm._v("至")]),_c('div',[_c('InputNumber',{attrs:{"max":150,"min":0},model:{value:(_vm.queryData.endNumber),callback:function ($$v) {_vm.$set(_vm.queryData, "endNumber", $$v)},expression:"queryData.endNumber"}})],1)])],1),_c('template',{slot:"channelId"},[_c('app-spacing',{staticClass:"age-range",attrs:{"spacing":"sm"}},[(this.userType !== 'OUTLET')?_c('div',[_c('el-select',{attrs:{"size":"small","filterable":"","clearable":"","value":_vm.queryData.channelId,"placeholder":"请选择渠道"},on:{"change":function (value) { return _vm.onChange(value); }}},_vm._l((_vm.queryConfigChannel),function(option){return _c('el-option',{key:option.id,attrs:{"value":option.id,"label":option.name}})}),1)],1):_vm._e()])],1)],2)],1),_c('app-data-table',{attrs:{"columns":_vm.REPORT_COLUMNS,"data":_vm.reports,"allowed-permissions":Object.values(_vm.REPORT_ACTION)},on:{"onPageConfigChange":_vm.fetchReports},scopedSlots:_vm._u([{key:"sex",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.EXPORT_SEX_OP[row.sex])+" ")]}},{key:"type",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.EXPORT_TYPE_OP[row.type])+" ")]}},{key:"number",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.number + "份")+" ")]}},{key:"action",fn:function(ref){
var row = ref.row;
return _c('app-spacing',{staticClass:"table-action",attrs:{"spacing":"sm"}},[(_vm.permissions['train:report:person'])?_c('Button',{attrs:{"type":"primary"},on:{"click":function () { return _vm.openListModal(row.id, true, row.customer_nick_name); }}},[_vm._v(" "+_vm._s(_vm.REPORT_ACTION_TEXT[_vm.REPORT_ACTION.CHECK])+" ")]):_vm._e()],1)}}]),model:{value:(_vm.queryData),callback:function ($$v) {_vm.queryData=$$v},expression:"queryData"}}),_c('Modal',{attrs:{"class-name":"app-modal","width":1000,"value":_vm.listModalData.visible,"title":((_vm.listModalData.userName) + "的训练报告"),"visible":_vm.listModalData.visible},on:{"on-cancel":function($event){return _vm.openListModal('', false, '')}}},[_c('div',{attrs:{"slot":"footer"},slot:"footer"}),_c('app-block-flex',[_c('app-query-bar',{attrs:{"queryConfig":_vm.queryConfigs},on:{"onSearch":_vm.reportList},model:{value:(_vm.listModalData),callback:function ($$v) {_vm.listModalData=$$v},expression:"listModalData"}})],1),_c('app-data-table',{attrs:{"columns":_vm.REPORT_LIST_COLUMNS,"data":_vm.listModalData.list,"allowed-permissions":Object.values(_vm.REPORT_ACTION)},on:{"onPageConfigChange":_vm.reportList},scopedSlots:_vm._u([{key:"store",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.store_name || "自主训练")+" ")]}},{key:"action",fn:function(ref){
var row = ref.row;
return _c('app-spacing',{staticClass:"table-action",attrs:{"spacing":"sm"}},[_c('Button',{attrs:{"type":"primary"},on:{"click":function () { return _vm.onViewReport(row.id); }}},[_vm._v(" 查看报告 ")])],1)}}]),model:{value:(_vm.listModalData),callback:function ($$v) {_vm.listModalData=$$v},expression:"listModalData"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }